import React from 'react';

const BlogPage = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>Blog</h1>
    <p>Check out our latest blog posts here.</p>
  </div>
);

export default BlogPage;
