// src/pages/HomePage.js
import React, { useRef } from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import CTAForm from '../components/CTAForm';
import MarketingBanner from '../components/MarketingBanner';  // Import MarketingBanner
import InnovativeServices from '../components/InnovativeServices';

const HomePage = () => {
  const ctaRef = useRef(null);  // Create a reference for the CTA form

  // Function to scroll to CTA form
  const scrollToCTA = () => {
    if (ctaRef.current) {
      ctaRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Pass scrollToCTA to Header */}
      <Header scrollToCTA={scrollToCTA} />
      <MarketingBanner />
      <HeroSection />
      <InnovativeServices />  {/* Add the services navigation to the center of the page */}
      
      {/* Reference the CTAForm */}
      <div ref={ctaRef}>
        <CTAForm />
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
