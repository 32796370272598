import React from 'react';
import styled from 'styled-components';
import strategyImage from '../assets/strategy-image.png';  // Image path
import goldBackground from '../assets/goldbackground.jpg';  // Background image

// Section container with a background image and 69% transparency
const SectionContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  background: 
    linear-gradient(rgba(0, 0, 0, 0.31), rgba(0, 0, 0, 0.31)),  /* 69% transparent overlay */
    url(${goldBackground});
  background-size: cover;  /* Make sure background covers entire section */
  background-position: center;
  color: white;  /* Text should stand out against background */
  
  @media (max-width: 768px) {
    flex-direction: column;  /* Stack on mobile */
  }
`;

// Text content container
const TextContent = styled.div`
  flex: 1;
  padding-right: 2rem;

  @media (max-width: 768px) {
    padding-right: 0;
    text-align: center;  /* Center text on mobile */
    margin-bottom: 2rem;
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;  /* Ensure heading is readable */
`;

const Subheading = styled.p`
  font-size: 1.2rem;
  color: white;
  line-height: 1.6;
`;

// Image container
const Image = styled.img`
  flex: 1;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const DigitalMarketingSection = () => (
  <SectionContainer>
    {/* Left Text Content */}
    <TextContent>
      <Heading>Digital Marketing Strategy that Drives Results</Heading>
      <Subheading>
        We bring you new customers by implementing highly effective strategies 
        tailored to your business. Our approach ensures you reach new prospects 
        and audiences across the entire web, from search engines to social media 
        and beyond.
      </Subheading>
    </TextContent>

    {/* Right Image */}
    <Image src={strategyImage} alt="Digital marketing strategy" />
  </SectionContainer>
);

export default DigitalMarketingSection;
