import React from 'react';

const CreativeServicesPage = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>Creative Services</h1>
    <p>Details about our creative services will go here.</p>
  </div>
);

export default CreativeServicesPage;
