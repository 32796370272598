// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';  // Import ChakraProvider
import HomePage from './pages/HomePage';
import DigitalMarketingPage from './pages/DigitalMarketingPage';
import SocialMediaPage from './pages/SocialMediaPage';
import PaidAdvertisingPage from './pages/PaidAdvertisingPage';
import CreativeServicesPage from './pages/CreativeServicesPage';
import ReputationBrandingPage from './pages/ReputationBrandingPage';
import BlogPage from './pages/BlogPage';
import GPBManagementPage from './pages/GPBManagement';  // Import GPB Management page
import Header from './components/Header';
import DigitalMarketingSection from './components/DigitalMarketingSection';
import ServicesSection from './components/ServicesSection';
import CTAForm from './components/CTAForm';  // Make sure CTAForm is correctly retained

const MainContent = ({ children }) => (
  <div style={{ paddingTop: '160px' }}>
    {children}
  </div>
);

function App() {
  return (
    <ChakraProvider>  {/* Wrap the app in ChakraProvider */}
      <Router>
        {/* Keep the Header (top banner) persistent across all pages */}
        <Header />
        <MainContent>
          <DigitalMarketingSection />
          <ServicesSection />
          <Routes>
            {/* Define all page routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/digital-marketing" element={<DigitalMarketingPage />} />
            <Route path="/social-media" element={<SocialMediaPage />} />
            <Route path="/paid-advertising" element={<PaidAdvertisingPage />} />
            <Route path="/creative-services" element={<CreativeServicesPage />} />
            <Route path="/reputation-branding" element={<ReputationBrandingPage />} />
            <Route path="/blog" element={<BlogPage />} />
            {/* Route for GPB Management */}
            <Route path="/pages/gpb-management" element={<GPBManagementPage />} />
          </Routes>
        </MainContent>
      </Router>
    </ChakraProvider>
  );
}

export default App;
