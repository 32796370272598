import React from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Text,
} from '@chakra-ui/react';

const ServicesSection = () => (
  <Box bg="black" py={16} px={8}>
    <Tabs variant="unstyled">
      <TabList mb="1em" display="flex" justifyContent="center">
        <Tab
          _selected={{
            bg: '#d1ab60',
            color: 'black',
            boxShadow: '0 0 0 2px white',  // Use boxShadow for a white border effect
          }}
          _hover={{
            bg: '#d1ab60',
            color: 'black',
            boxShadow: '0 0 0 2px white',  // White border on hover
          }}
          bg="#d1ab60"  // Default background color
          color="black"
          fontWeight="bold"
          borderRadius="md"
          px={4}
          py={2}
          mx={2}  // Add horizontal margin (both left and right) for spacing
        >
          ONLINE REPUTATION MANAGEMENT
        </Tab>
        <Tab
          _selected={{
            bg: '#d1ab60',
            color: 'black',
            boxShadow: '0 0 0 2px white',  // Use boxShadow for a white border effect
          }}
          _hover={{
            bg: '#d1ab60',
            color: 'black',
            boxShadow: '0 0 0 2px white',  // White border on hover
          }}
          bg="#d1ab60"  // Default background color
          color="black"
          fontWeight="bold"
          borderRadius="md"
          px={4}
          py={2}
          mx={2}  // Add horizontal margin (both left and right) for spacing
        >
          CONVERSION RATE OPTIMIZATION
        </Tab>
        <Tab
          _selected={{
            bg: '#d1ab60',
            color: 'black',
            boxShadow: '0 0 0 2px white',  // Use boxShadow for a white border effect
          }}
          _hover={{
            bg: '#d1ab60',
            color: 'black',
            boxShadow: '0 0 0 2px white',  // White border on hover
          }}
          bg="#d1ab60"  // Default background color
          color="black"
          fontWeight="bold"
          borderRadius="md"
          px={4}
          py={2}
          mx={2}  // Add horizontal margin (both left and right) for spacing
        >
          WEBSITE DESIGN & SOCIAL MEDIA
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel textAlign="center">  {/* Center the text horizontally */}
          <Heading as="h3" size="lg" color="#d1ab60" mb={4}>
            ONLINE REPUTATION MANAGEMENT
          </Heading>
          <Text color="white">
            Analyze negative materials about your brand and address them with sentiment analysis and press release distribution.
          </Text>
        </TabPanel>

        <TabPanel textAlign="center">  {/* Center the text horizontally */}
          <Heading as="h3" size="lg" color="#d1ab60" mb={4}>
            CONVERSION RATE OPTIMIZATION
          </Heading>
          <Text color="white">
            Turn your visitors into customers with our team of experts. We'll analyze 
            your website and create a conversion-rate strategy.
          </Text>
        </TabPanel>

        <TabPanel textAlign="center">  {/* Center the text horizontally */}
          <Heading as="h3" size="lg" color="#d1ab60" mb={4}>
            WEBSITE DESIGN & SOCIAL MEDIA
          </Heading>
          <Text color="white">
            We ensure your website aligns with your social media marketing efforts and creates a consistent brand presence. Whether we are building your site from
            scratch or improving your current platform, we will make sure it matches your brand identity.
          </Text>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>
);

export default ServicesSection;
