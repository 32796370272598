import React from 'react';
import styled from 'styled-components';
import seoImage from '../assets/seo-image.png';  // Replace with your actual image path

// Container for the whole section
const BannerContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 50px 100px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 30px;
  }
`;

// Content container for text
const TextContainer = styled.div`
  flex: 1;
  padding-left: 50px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

// Styled heading for the title
const Title = styled.h2`
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Styled paragraph for the description
const Description = styled.p`
  font-size: 1.2rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Image container
const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 100%;
    max-width: 400px;  // Adjust the image size
    height: auto;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const MarketingBanner = () => {
  return (
    <BannerContainer>
      {/* Left Image */}
      <ImageContainer>
        <img src={seoImage} alt="SEO Illustration" />
      </ImageContainer>

      {/* Right Text Content */}
      <TextContainer>
        <small style={{ color: 'gold', fontSize: '1rem' }}>GROW TRAFFIC & INCREASE REVENUE</small>
        <Title>Want to rank higher on Search Engines?</Title>
        <Description>
          We offer professional SEO services that help websites increase their organic search score drastically in order to compete for the highest rankings — even when it comes to highly competitive keywords.
        </Description>
      </TextContainer>
    </BannerContainer>
  );
};

export default MarketingBanner;
