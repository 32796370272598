// src/pages/GPBManagement.js
import React from 'react';
import styled from 'styled-components';
import goldHexagon from '../assets/goldhexagon.jpeg';  // Background image
import googleAds from '../assets/googleads.jpeg';  // Left image

const PageContainer = styled.div`
  background: url(${goldHexagon}) center/cover no-repeat;
  background-size: cover;
  padding: 100px 20px;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding-right: 20px;

  img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-left: 20px;
  color: white;

  h1 {
    font-size: 2.5rem;
    color: gold;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  ul {
    margin-top: 20px;
    padding-left: 20px;
  }

  li {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const GPBManagementPage = () => {
  return (
    <PageContainer>
      <ContentContainer>
        {/* Left Image */}
        <ImageContainer>
          <img src={googleAds} alt="Google Ads" />
        </ImageContainer>

        {/* Right Text */}
        <TextContainer>
          <h1>GBP Management</h1>
          <p>
            Google Business Profile (GBP) is one of the most important online platforms your business should be listed on as part of a good local SEO strategy...
          </p>

          <ul>
            <li>Manage all your reviews in one place</li>
            <li>Boost your local pack ranking</li>
            <li>Increase your conversion rate</li>
          </ul>

          <p>
            Distributing your business information across Google is one of the leading ways to gain new customers and start expanding on your both your business and your company’s SEO Strategy.
          </p>
        </TextContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default GPBManagementPage;
