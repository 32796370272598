import React from 'react';

const DigitalMarketingPage = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>Digital Marketing Services</h1>
    <p>Details about our digital marketing services will go here.</p>
  </div>
);

export default DigitalMarketingPage;
