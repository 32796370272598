import React, { useState } from 'react';
import styled from 'styled-components';

// CTA Section Container
const CTAContainer = styled.section`
  background-color: black;
  padding: 40px;
  color: white;
  text-align: center;
  margin-top: 50px;
`;

// CTA Heading
const CTAHeading = styled.h2`
  font-size: 2rem;
  color: gold;
  margin-bottom: 20px;
`;

// Form Styling
const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  background-color: #222;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`;

// Input Fields
const InputField = styled.div`
  margin-bottom: 20px;
  text-align: left;

  label {
    color: gold;
    display: block;
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #333;
    color: white;
  }

  input::placeholder,
  textarea::placeholder {
    color: #bbb;
  }
`;

// Budget Slider
const Slider = styled.input`
  width: 100%;
  margin: 10px 0;
`;

// Submit Button
const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: gold;
  border: none;
  border-radius: 5px;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;

  &:hover {
    background-color: darkgoldenrod;
  }
`;

const CTAForm = () => {
  const [budget, setBudget] = useState(5000); // Default budget value

  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
  };

  return (
    <CTAContainer>
      <CTAHeading>Get Your Free Marketing Consultation</CTAHeading>
      <p>Fill out the form below, and we'll get in touch with you!</p>
      
      <Form action="https://formspree.io/f/mkgnnvyo" method="POST">
        {/* First Name */}
        <InputField>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Enter your first name"
            required
          />
        </InputField>

        {/* Last Name */}
        <InputField>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Enter your last name"
            required
          />
        </InputField>

        {/* Business Name */}
        <InputField>
          <label htmlFor="businessName">Business Name</label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            placeholder="Enter your business name"
            required
          />
        </InputField>

        {/* URL */}
        <InputField>
          <label htmlFor="url">Website URL</label>
          <input
            type="url"
            id="url"
            name="url"
            placeholder="Enter your website URL"
            required
          />
        </InputField>

        {/* Budget Slider */}
        <InputField>
          <label htmlFor="budget">Budget Monthly: ${budget}</label>
          <Slider
            type="range"
            id="budget"
            name="budget"
            min="250"
            max="10000"
            step="250"
            value={budget}
            onChange={handleBudgetChange}
          />
        </InputField>

        {/* Business Description */}
        <InputField>
          <label htmlFor="businessDescription">Business Description</label>
          <textarea
            id="businessDescription"
            name="businessDescription"
            rows="4"
            placeholder="Describe your business and marketing needs"
            required
          ></textarea>
        </InputField>

        {/* Hidden input to redirect after form submission */}
        <input type="hidden" name="_next" value="https://risemyrank.com/thank-you" />

        {/* Submit Button */}
        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
    </CTAContainer>
  );
};

export default CTAForm;
