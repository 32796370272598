import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';  // Import Link and useNavigate
import logo from '../assets/risemyranklogo.png';  // Path to logo

// Header Container with fixed position
const HeaderContainer = styled.header`
  background-color: black;
  padding: 0;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Adjust logo size to 150x150
const Logo = styled.img`
  width: 150px;
  height: 150px;
  margin-left: 20px;
`;

// Navigation links for desktop
const Nav = styled.nav`
  display: flex;
  align-items: center;
  position: relative;

  a {
    color: white;
    margin: 0 1.5rem;
    text-decoration: none;
    font-size: 1rem;
    &:hover {
      color: gold;
    }
  }

  button {
    background-color: gold;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 20px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darkgoldenrod;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

// Hamburger Button for mobile
const HamburgerButton = styled.button`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;

  span {
    background-color: gold;
    height: 2px;
    width: 25px;
    margin: 4px 0;
    transition: all 0.3s ease;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

// Mobile Nav menu (collapsible)
const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  position: absolute;
  top: 150px;
  right: 0;
  width: 100%;
  padding: 1rem;
  z-index: 10;
  transition: max-height 0.3s ease;
  overflow: hidden;

  a {
    color: white;
    margin: 1rem 0;
    text-decoration: none;
    font-size: 1.5rem;
    &:hover {
      color: gold;
    }
  }

  button {
    background-color: gold;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darkgoldenrod;
    }
  }

  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};  /* Collapsing */
`;

const Header = ({ scrollToCTA }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();  // useNavigate hook to navigate

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleHomeClick = (event) => {
    event.preventDefault();  // Prevent page reload
    window.scrollTo({ top: 0, behavior: 'smooth' });  // Smooth scroll to the top
    navigate('/');  // Ensure navigation to the homepage
  };

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={logo} alt="Rise My Rank Logo" />
      </Link>

      {/* Desktop navigation with Home, Blog, and Free Consultation button */}
      <Nav>
        {/* Use Link for smooth navigation and add onClick handler */}
        <Link to="/" onClick={handleHomeClick}>Home</Link>
        <Link to="/blog">Blog</Link>
        <button onClick={scrollToCTA}>Free Consultation</button>
      </Nav>

      {/* Hamburger menu button for mobile */}
      <HamburgerButton onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerButton>

      {/* Mobile navigation with Home, Blog, and Free Consultation button */}
      <MobileNav isOpen={isOpen}>
        <Link to="/" onClick={() => { toggleMenu(); handleHomeClick(); }}>Home</Link>
        <Link to="/blog" onClick={toggleMenu}>Blog</Link>
        <button onClick={() => { toggleMenu(); scrollToCTA(); }}>Free Consultation</button>
      </MobileNav>
    </HeaderContainer>
  );
};

export default Header;
