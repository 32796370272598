// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: black;
  color: gold;
  padding: 2rem;
  text-align: center;
`;

const Footer = () => (
  <FooterContainer>
    <p>© 2024 Rise My Rank. All rights reserved.</p>
  </FooterContainer>
);

export default Footer;
