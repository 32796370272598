import React from 'react';

const SocialMediaPage = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>Social Media</h1>
    <p>Details about our social media services will go here.</p>
  </div>
);

export default SocialMediaPage;
