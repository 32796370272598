import React from 'react';
import styled from 'styled-components';
import goldHexagon from '../assets/goldhexagon.jpeg'; // Replace with the correct path to your image

// Hero Container with the background image
const HeroContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${goldHexagon}) center/cover no-repeat;
  height: 600px;  // Adjust height as needed
  color: white;

  /* Overlay with 50% opacity */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);  // Black overlay for 50% opacity effect
    z-index: 1;
  }

  @media (max-width: 768px) {
    height: 450px;
    text-align: center;
  }
`;

// Content inside the hero section
const HeroContent = styled.div`
  position: relative;
  z-index: 2;  // Ensure the text is above the overlay
  text-align: center;
  max-width: 800px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

// Hero Heading (Grow Your Brand)
const HeroHeading = styled.h1`
  font-size: 3rem;
  color: gold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

// Hero Description
const HeroDescription = styled.p`
  font-size: 1.3rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroHeading>Grow Your Brand with Our Digital Marketing Solutions</HeroHeading>
        <HeroDescription>
          We offer tailored digital marketing strategies designed to enhance your online presence, drive traffic, and increase conversions. Let us help you elevate your brand to the next level with our expert services.
        </HeroDescription>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
