// src/components/InnovativeServices.js
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Grid,
  Link,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

// Import images from src/assets folder
import DigitalMarketingImage from '../assets/digitalmarketing.png';
import GBPManagementImage from '../assets/googleads.jpeg';
import SEOImage from '../assets/seo.png';
import ContentMarketingImage from '../assets/content-marketing.png';
import EmailMarketingImage from '../assets/email-marketing.png';
import ReputationManagementImage from '../assets/reputation-management.png';
import LocalSEOImage from '../assets/local-seo.png';
import MobileMarketingImage from '../assets/mobile-marketing.png';
import CompetitiveAnalysisImage from '../assets/competitive-analysis.png';
import SocialMediaImage from '../assets/social-media.png';
import FacebookMarketingImage from '../assets/facebook-marketing.png';
import InstagramMarketingImage from '../assets/instagram-marketing.png';
import YouTubeMarketingImage from '../assets/youtube-marketing.png';
import SocialMediaManagementImage from '../assets/social-media-management.png';
import PaidAdvertisingImage from '../assets/paid-advertising.png';
import GoogleAdsImage from '../assets/google-ads.png';
import DisplayAdsImage from '../assets/display-ads.png';
import PPCCampaignsImage from '../assets/ppc-campaigns.png';
import RemarketingImage from '../assets/remarketing.png';
import CreativeServicesImage from '../assets/creative-services.png';
import BrandIdentityImage from '../assets/brand-identity.png';
import WebsiteDesignImage from '../assets/website-design.png';
import GraphicDesignImage from '../assets/graphic-design.png';
import VideoProductionImage from '../assets/video-production.png';
import BrandedMerchandiseImage from '../assets/branded-merchandise.png'; // New image for Branded Merchandise
import EventBookingImage from '../assets/event-booking.png'; // New image for Event Booking
import EventManagementImage from '../assets/event-management.png'; // New image for Event Management
import GoldGreyBackground from '../assets/goldgrey.png'; // Make sure the path is correct

const services = [
  // DIGITAL MARKETING SERVICES
  {
    title: 'Digital Marketing Services',
    description: 'Reach new customers with a full suite of digital marketing strategies.',
    image: DigitalMarketingImage, // Use the imported image
    subcategories: [
      { 
        name: 'GBP Management',
        description: 'Manage and optimize your Google Business Profile (GBP) to improve your local SEO rankings.',
        image: GBPManagementImage,  // Image for GBP Management
        bulletPoints: [
          'Manage all your reviews in one place',
          'Boost your local pack ranking',
          'Increase your conversion rate',
        ],
        additionalInfo: `Google Business Profile (GBP) is one of the most important platforms for your business as part of a good local SEO strategy. Good GBP optimization services can quickly get your business details featured in other directories to help Google verify your NAP data (name, address, phone number), which significantly impacts your ranking.`,
      },
      { 
        name: 'SEO',
        description: 'SEO offers a visible and effective search presence leading to a considerable increase of sales, profitability, and cost efficiency for our clients.',
        image: SEOImage,
        bulletPoints: [
          'Organic Search: Development and targeted promotion of remarkable content that fulfills your needs by achieving, improving and maintaining the visibility of your web presence.',
          'On-Page SEO: Make your content easily accessible to search engines. Our team is ready to implement solutions to boost your rankings and improve your performance.',
          'Link Building: An essential part of any successful SEO strategy, let’s build your internal link profile to add significance to your page keywords listed within search engines.',
        ],
        additionalInfo: `Search Engine Optimization is fundamental to success. Our SEO strategies will earn your site a higher ranking in search results. We’ll provide a full SEO keyword rankings report, a link building profile report and indexed page information. Our customer focused team will improve your traffic flow and increase sales for your web-based operations..`,
      },
      {
        name: 'Content Marketing',
        description: 'We tailor a strategy specifically designed around content creation and a content marketing strategy for each client. Our experienced copywriters know exactly how to make words sell.',
        image: ContentMarketingImage,
        bulletPoints: [
          'Industry News: Up-to-date industry related posts that inform, educate and entertain readers leading to an increase in social media engagement, improve your online reputation and gain credibility. ',
          'Case Studies: Inspire confidence in your target audience with in-depth reports to summarize projects from start to finish and explicitly document the results achieved in performance measures.',
          'Content Strategy: Achieve your business objectives with a comprehensive business analysis Content Strategy provided by our highly qualified copywriters and analysts.',
          'Interviews Writing: Gain authority in your industry or business by sharing comments from industry experts. Share their expertise and you will immediately see feedback from your audience.',
          'Press Releases: Boost your online profile and increase your website traffic with outstanding newsworthy stories about your brand, submitted to news portals and press release distribution services.',
          'White Papers: Promote your business with high-quality white paper marketing that will substantially increase your social media engagement levels and solidify your position in the industry.',
        ],
        additionalInfo: `Content Marketing builds communities, inspires engagement and increases rankings via creation and sharing of high quality, problem-solving relevant information, aiming to satisfy business objectives. It helps influence consumer behavior, with the end goal of boosting sales and increasing interaction between the brand and customer.`,
      },
      {
        name: 'Email Marketing',
        description: 'We create tailored marketing campaigns for each segment of your audience to help advertise products and services in efforts to efficiently and effectively engage new customers.',
        image: EmailMarketingImage,
        bulletPoints: [
          'The Best Email Marketing Strategy: Our team will develop the best strategy to attract the defined target audiences and establish their information needs. ',
          'Email Marketing Campaigns: Our team is ready to design, build and supply an email template for communication with your audience as well manage your email marketing campaign to lead to dramatic shifts in your sales and profitability. ',
          'Email Template Design: Our innovative designs and solutions are compliant and improve email deliverability. Our professional designers bring your message to life and increase your target audience engagement with your brand. ',
        ],
        additionalInfo: `Email marketing provides an effective connection with your targeted audience and gives you the best return on investment. Reach out to your current and potential customers and keep them updated on your company’s latest deals and news.

Marketers agree that email is a key to business, especially for ROI and developing long-lasting profitable relationships. A personalized, segmented and targeted email marketing campaign is important for brand awareness and drive conversions.`,
      },
      {
        name: 'Reputation Management',
        description: 'Manage and improve your online reputation across the web.',
        image: ReputationManagementImage,
        bulletPoints: [
          'Monitor and manage reviews',
          'Respond to negative feedback',
          'Build a positive online presence',
        ],
        additionalInfo: `Reputation management is crucial for maintaining trust in your brand. By monitoring reviews, addressing customer feedback, and promoting positive interactions, you can enhance your brand's reputation online.`,
      },
      {
        name: 'Local SEO',
        description: 'Optimize your website and presence for local search visibility.',
        image: LocalSEOImage,
        bulletPoints: [
          'Google My Business optimization',
          'Local keyword targeting',
          'NAP (Name, Address, Phone) consistency',
        ],
        additionalInfo: `Local SEO ensures that your business is easily found by potential customers searching for services in your area. By optimizing for local search terms and maintaining consistency in your business information, you can improve your local rankings.`,
      },
      {
        name: 'Mobile Marketing',
        description: 'Engage customers through mobile channels with targeted campaigns.',
        image: MobileMarketingImage,
        bulletPoints: [
          'SMS marketing',
          'Mobile-optimized content',
          'Push notifications',
        ],
        additionalInfo: `Mobile marketing involves reaching customers through their smartphones and other mobile devices. This includes SMS campaigns, push notifications, and creating mobile-friendly content that engages users on the go.`,
      },
      {
        name: 'Competitive Analysis',
        description: 'Analyze competitors to identify growth opportunities and improve strategy.',
        image: CompetitiveAnalysisImage,
        bulletPoints: [
          'Identify competitor strengths and weaknesses',
          'Benchmark your performance against competitors',
          'Develop strategies to outperform competitors',
        ],
        additionalInfo: `Competitive analysis helps you understand your competitors' strengths and weaknesses, identify areas where you can outperform them, and refine your strategy to stay ahead in the market.`,
      },
    ],
  },

  // SOCIAL MEDIA SERVICES
  {
    title: 'Social Media Services',
    description: 'Grow your brand and engage customers on the most popular social media platforms.',
    image: SocialMediaImage,
    subcategories: [
      { 
        name: 'Facebook Marketing',
        description: 'Leverage Facebook ads and content to build your brand.',
        image: FacebookMarketingImage,
        bulletPoints: [
          'Custom Facebook ad targeting',
          'Engaging posts and stories',
          'Audience retargeting',
        ],
        additionalInfo: `Facebook Marketing helps businesses reach a large audience through targeted ads and organic content. By creating compelling ads and posts, businesses can increase brand awareness and drive traffic to their website or product pages.`,
      },
      { 
        name: 'Instagram Marketing',
        description: 'Reach and engage audiences with creative Instagram campaigns.',
        image: InstagramMarketingImage,
        bulletPoints: [
          'Visual content strategies',
          'Instagram Stories and Reels',
          'Influencer partnerships',
        ],
        additionalInfo: `Instagram is a visual platform ideal for businesses to connect with audiences through creative images and videos. Effective Instagram marketing can drive brand loyalty and engagement.`,
      },
      {
        name: 'YouTube Marketing',
        description: 'YouTube is the world’s largest video repository and the second largest search engine in the world. Neither of those facts should surprise you since 300 hours of video are uploaded to YouTube every minute.',
        image: YouTubeMarketingImage,
        bulletPoints: [
          'Almost five billion videos are watched on YouTube every day.',
          'One billion of those views are on smart phones.',
          'The average mobile viewing session lasts more than 40 minutes.',
          'The number of YouTube channels earning six figures per year is up 50% over 2016.',
          'Only nine percent of U.S. small businesses are using YouTube to market their goods and services.',
        ],
        additionalInfo: `YouTube Marketing is one of the most important types of marketing you can do. There are so many factors that go into YouTube properly promoting and sharing your video, it helps to know exactly what's needed when creating and promoting your channel.`,
      },
      {
        name: 'Social Media Management',
        description: 'Manage and grow your presence across multiple social platforms.',
        image: SocialMediaManagementImage,
        bulletPoints: [
          'Daily content posting',
          'Engagement and community management',
          'Analytics and performance tracking',
        ],
        additionalInfo: `Social Media Management involves overseeing and managing content and interactions on various social media platforms. It includes scheduling posts, engaging with the audience, and analyzing performance to improve strategies.`,
      },
    ],
  },

  // PAID ADVERTISING SERVICES
  {
    title: 'Paid Advertising Services',
    description: 'Maximize your return on investment with paid advertising solutions.',
    image: PaidAdvertisingImage,
    subcategories: [
      { 
        name: 'Google Ads',
        description: 'Create and manage PPC campaigns on Google Search and Display Network.',
        image: GoogleAdsImage,
        bulletPoints: [
          'Search and display ads',
          'Budget optimization',
          'Detailed performance tracking',
        ],
        additionalInfo: `Google Ads is an effective way to drive traffic to your site using paid search and display advertising. By targeting specific keywords and audiences, you can increase visibility and conversions.`,
      },
      { 
        name: 'Facebook Ads',
        description: 'Use visual ads to capture attention on websites across the Google network.',
        image: DisplayAdsImage,
        bulletPoints: [
          'Banner and display ad creation',
          'Ad placement across popular sites',
          'Custom visual branding',
        ],
        additionalInfo: `Display ads use eye-catching visuals to attract potential customers. By placing these ads across a variety of websites, you can capture user interest and drive them to your site.`,
      },
      {
        name: 'PPC Campaigns',
        description: 'Drive traffic and conversions with Pay-Per-Click advertising.',
        image: PPCCampaignsImage,
        bulletPoints: [
          'Keyword-focused campaigns',
          'Performance-driven bidding strategies',
          'ROI-focused campaign optimization',
        ],
        additionalInfo: `PPC campaigns allow you to pay for top spots on search engines and other platforms to drive targeted traffic to your site. Well-optimized campaigns improve conversion rates and maximize your return on investment.`,
      },
      {
        name: 'Instagram Ads',
        description: 'Instagram is no longer a social media network that is solely used for posting and sharing photos and videos with friends and family. Today, it has become one of the fastest growing and most utilized social media platforms for brands to promote their products or services. And the good thing about it is that active users are actually looking for this content- whether organic posts or paid ad campaigns. So you need to take advantage of that! And that is where our Instagram Ad management comes in.',
        image: RemarketingImage,
        bulletPoints: [
          'Retarget users with relevant ads',
          'Improve conversion rates',
          'Create custom audience segments',
        ],
        additionalInfo: `Remarketing allows you to reach out to users who have already visited your site and shown interest in your products or services. It’s a powerful way to remind them to come back and convert.`,
      },
    ],
  },

  // CREATIVE SERVICES
  {
    title: 'Creative Services',
    description: 'Enhance your brand’s identity and customer experience with creative solutions.',
    image: CreativeServicesImage,
    subcategories: [
      { 
        name: 'Brand Identity Design',
        description: 'Create a consistent and compelling visual brand identity.',
        image: BrandIdentityImage,
        bulletPoints: [
          'Logo and branding guidelines',
          'Consistent visual identity across platforms',
          'Creative brand storytelling',
        ],
        additionalInfo: `Brand identity design involves crafting the visual elements of your brand, including logos, fonts, and colors. A strong brand identity helps your business stand out and resonate with customers.`,
      },
      {
        name: 'Website Design',
        description: 'Design responsive, user-friendly websites that convert visitors.',
        image: WebsiteDesignImage,
        bulletPoints: [
          'Responsive website designs',
          'UX and UI optimization',
          'SEO-friendly website architecture',
        ],
        additionalInfo: `Effective website design combines aesthetic appeal with usability and performance. A well-designed website not only looks great but also converts visitors into customers.`,
      },
      {
        name: 'Graphic Design',
        description: 'Design custom graphics and visuals to enhance your marketing campaigns.',
        image: GraphicDesignImage,
        bulletPoints: [
          'Custom graphic creation',
          'Brand-aligned visual designs',
          'Professional marketing materials',
        ],
        additionalInfo: `Graphic design services help your brand stand out with unique and eye-catching visuals. From social media graphics to marketing materials, professional design enhances your overall brand presence.`,
      },
      {
        name: 'Video Production',
        description: 'Create high-quality videos to engage your audience and tell your brand’s story.',
        image: VideoProductionImage,
        bulletPoints: [
          'Scriptwriting and storyboarding',
          'Professional video editing',
          'Engaging visual storytelling',
        ],
        additionalInfo: `Video production services help you create compelling visual content to tell your brand's story. From promotional videos to customer testimonials, high-quality videos can captivate your audience and drive engagement.`,
      },
    ],
  },
  {
    title: 'Reputation/Management',
    description: 'Boost your brand’s image with tailored merchandise and services.',
    image: ReputationManagementImage,
    subcategories: [
      {
        name: 'Branded Merchandise',
        description: 'Create and distribute custom branded merchandise to boost brand recognition.',
        image: BrandedMerchandiseImage,  // Use new image
        bulletPoints: [
          'Custom merchandise creation',
          'Product distribution strategies',
          'Increase brand visibility',
        ],
        additionalInfo: `Branded merchandise helps your company increase visibility and leave a lasting impression on your customers. From apparel to promotional items, these products are a great way to boost your brand’s image.`,
      },
    ],
  },

  // NEW - EVENT BOOKING SERVICES
  {
    title: 'Event Booking',
    description: 'Seamlessly manage and book events with our specialized services.',
    image: EventBookingImage,
    subcategories: [
      {
        name: 'Event Management',
        description: 'Plan, coordinate, and execute successful events for your brand.',
        image: EventManagementImage,  // Use new image
        bulletPoints: [
          'Comprehensive event planning',
          'Logistics and coordination',
          'Marketing and promotion',
        ],
        additionalInfo: `Event management involves planning, coordinating, and executing successful events that promote your brand. Whether it’s a product launch, conference, or promotional event, our team ensures everything runs smoothly.`,
      },
    ],
  },
];

const InnovativeServices = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeSubcategory, setActiveSubcategory] = useState(null);

  const handleSubcategoryClick = (subcategory) => {
    setActiveSubcategory(subcategory);
    onOpen();
  };

  return (
    <Box w="100%" maxW="1200px" mx="auto" py={10} px={5}>
      <Heading as="h2" size="xl" textAlign="center" mb={10} fontWeight="bold">
        Our Innovative Services
      </Heading>

      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={8}>
        {services.map((service, index) => (
          <Box
            key={index}
            position="relative"
            overflow="hidden"
            borderRadius="md"
            boxShadow="lg"
            _hover={{ transform: 'scale(1.05)' }}
            transition="all 0.3s ease"
            cursor="pointer"
            bg="white"
            h="320px"
          >
            {/* Service Image */}
            <Image
              src={service.image}
              alt={service.title}
              objectFit="cover"
              w="100%"
              h="350px"
              transition="transform 0.3s ease"
              _hover={{ transform: 'scale(1.2)' }}
            />

            {/* Overlay with Service Title */}
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              bg="rgba(0, 0, 0, 0.6)"
              opacity="0"
              _hover={{ opacity: 1 }}
              transition="opacity 0.3s ease"
              color="white"
              textAlign="center"
              p={5}
            >
              <Heading as="h3" size="lg" mb={2}>
                {service.title}
              </Heading>
              <Text mb={3}>{service.description}</Text>

              {/* Subcategories List with Grid Layout for Multiple Rows */}
              <Grid templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }} gap={3} mt={3}>
                {service.subcategories.map((sub, idx) => (
                  <Link
                    key={idx}
                    onClick={() => handleSubcategoryClick(sub)}
                    fontSize="sm"
                    color="white"
                    textDecoration="underline"
                    cursor="pointer"
                  >
                    {sub.name}
                  </Link>
                ))}
              </Grid>
            </Flex>
          </Box>
        ))}
      </Grid>

      {/* Modal to Show Subcategory Details */}
      {activeSubcategory && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{activeSubcategory.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Add an image to the modal */}
              {activeSubcategory.image && (
                <Image src={activeSubcategory.image} alt={activeSubcategory.name} mb={4} />
              )}

              {/* Add a description with paragraph breaks */}
              <Text mb={4}>
                {activeSubcategory.description}
              </Text>

              {/* Add additional paragraphs */}
              <Text mb={4}>
                {activeSubcategory.additionalInfo}
              </Text>

              {/* Add bullet points */}
              {activeSubcategory.bulletPoints && (
                <UnorderedList mb={4}>
                  {activeSubcategory.bulletPoints.map((point, idx) => (
                    <ListItem key={idx}>{point}</ListItem>
                  ))}
                </UnorderedList>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default InnovativeServices;
